import React, { useState } from "react";
import classNames from "classnames";

const GameButton = (props) => {
  const { link, logo, showNew, id, showSoon } = props;

  return (
    <div id={id} className="switch-icon-wrapper">
      <a href={link} className={classNames("game-switch-icon")}>
        {logo}
      </a>
      {showNew && <div className="new-icon">NEW!</div>}
      {showSoon && <div className="soon-icon">SOON™</div>}
    </div>
  );
};

export default GameButton;
