import React from "react";

export const TierChampionPlaceholder = (props) => {
  return (
    <div className="tier-champion placeholder">
      <div className="champion-link">
        <div className="champion-wrapper">
          <div className="champion-image"></div>
          <div className={`champion-tier`}></div>
          <div className="champion-keystone"></div>
        </div>
        <div className="champion-name"></div>
        <div className="view-btn"></div>
      </div>
    </div>
  );
};

export const ProChampionPlaceholder = (props) => {
  return (
    <div className="pro-champion placeholder">
      <div className="champion-link">
        <div className="champion-wrapper">
          <div className="champion-image"></div>
        </div>
        <div className="champion-name"></div>
        <div className="champion-matches"></div>
        <div className="view-btn"></div>
      </div>
    </div>
  );
};
