import { ReactComponent as RightArrow } from "svg/right-arrow-head.svg";
import { ReactComponent as WowLogo } from "svg/WoW-SelectorLogo.svg";

const TockersPromo2 = () => {
  return (
    <a
      href="https://u.gg/wow/tier-list/dps-rankings/raid?utm_source=ugghometopbanner&encounter=all_bosses"
      className="tockers-promo-container"
    >
      <div className="promo-content">
        <WowLogo className="tockers-logo" />
        <div className="first-section">
          <div className="first-row">
            <div className="new-tag">NEW</div>
            <span className="top-text">WORLD OF WARCRAFT: THE WAR WITHIN</span>
          </div>
          <span className="bottom-text">Builds, Tier Lists and Hero Talents for WoW's latest expansion</span>
        </div>
        <div className="cta-button">
          <span>Check it out</span> <RightArrow className="arrow-icon" />
        </div>
      </div>
    </a>
  );
};

export default TockersPromo2;
