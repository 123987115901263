import { useGlobal } from "reactn";
import { useUserPremiumState } from "@ugg/shared/api/requests/accounts/user-state";

export function useAdFree() {
  const [adblock] = useGlobal("adblock");
  const [isDetectingAdblock] = useGlobal("isDetectingAdblock");
  const [loggingIn] = useGlobal("loggingIn");
  const { data, loading } = useUserPremiumState();

  // Check if adblock running
  if (adblock || isDetectingAdblock) {
    return true;
  }
  // Check if adfree premium
  if (loading || loggingIn || data?.isPremium) {
    return true;
  }
  return false;
}
