import { window } from "global";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGlobal } from "reactn";
import MediaQuery from "components/MediaQuery";
import DesktopAppLandingPage from "components/Pages/LandingPage/views/DesktopAppLandingPage";
import LandingPageMobile from "components/Pages/LandingPage/views/mobile/LandingPageContainer";

import { usePageTitleAndDesc } from "lib/seo_helper";
import { useQuery } from "@apollo/client";
import { useSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";

const LandingPageContainer = (props) => {
  const location = useLocation();
  const history = useHistory();
  const getPageTitleAndDesc = usePageTitleAndDesc();
  const [windowBreakpoint] = useGlobal("responsive");
  const [loggingIn] = useGlobal("loggingIn");
  const [verificationBar] = useGlobal("verificationBar");
  const [isInitialPage] = useState(
    (window && sessionStorage.getItem("initial_page")) === "/" ||
      (window && sessionStorage.getItem("initial_page")) === "/verify",
  );
  const isVerificationLink = location.pathname === "/verify";

  useEffect(() => {
    window && sessionStorage.removeItem("initial_page");

    // Remove skin ads
    try {
      window.top.document.getElementById("siteSkinContainer").remove();
    } catch (e) {}
  }, []);

  const {
    loading: loadingUserState,
    data: userState,
    error: errorUserState,
  } = useQuery(GET_USER_STATE, { fetchPolicy: "network-only" });

  const { getState } = userState || {};
  const { isVerified, lolSettings, premium } = getState || {};
  const { isPremium } = premium || {};
  const { summoners } = lolSettings || {};
  const { riotUserName, riotTagLine, regionId } = (summoners && summoners[0]) || {};
  const accountBreakpoints = ["TABLET", "DESKTOP_SMALL", "DESKTOP_MEDIUM", "DESKTOP_LARGE"];
  const canRedirectToProfile = isInitialPage && accountBreakpoints.includes(windowBreakpoint); // Disabling this functionality

  const {
    loading: loadingSummonerProfile,
    data: summonerProfile,
    error: errorSummonerProfile,
  } = useSummonerProfileInitSimple(regionId, riotUserName, riotTagLine, {}, { skip: !userState });

  useEffect(() => {
    if (canRedirectToProfile) {
      if (isVerificationLink && !verificationBar) {
        return;
      }
      if (userState && !loadingSummonerProfile) {
        // Redirect to 404 user summoner profile page
        if (!summonerProfile) {
          history.replace("/not-found-user-summoner-profile");
        }
        // Redirect to summoner profile page
        else if (summonerProfile) {
          history.replace(getProfileOverviewUrl(regionId, riotUserName, riotTagLine));
        }
      }
    }
  }, [loadingUserState, loadingSummonerProfile, windowBreakpoint, verificationBar]);

  let content = null;
  if (!window || loggingIn || loadingUserState) {
    content = null;
  } else if (
    loadingSummonerProfile ||
    (canRedirectToProfile && summonerProfile) ||
    (isVerificationLink && !loggingIn && !verificationBar)
  ) {
    content = (
      <div className="landing-page-container landing-page_loading">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  } else {
    content = (
      <React.Fragment>
        <MediaQuery min="TABLET" max="DESKTOP_LARGE" renderNullOnFail>
          <div className="landing-page-container">
            <DesktopAppLandingPage />
          </div>
        </MediaQuery>
        <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE" renderNullOnFail>
          <LandingPageMobile />
        </MediaQuery>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {getPageTitleAndDesc("index")}
      {content}
    </React.Fragment>
  );
};

export default LandingPageContainer;
