const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

function findIndex(value, options) {
  const optionValues = options.map((r) => r.value);
  const index = optionValues.findIndex((optionValue) => optionValue === value);
  return index;
}

function getTierListPickRateCutoff(queueType = null) {
  // Changed from 1.0% -> 0.5%
  if (queueType === "one_for_all") {
    return 0;
  }
  return 0.5;
}

function getTier(winrate) {
  if (winrate < 45) {
    return "D";
  } else if (winrate < 48.5) {
    return "C";
  } else if (winrate < 51.5) {
    return "B";
  } else if (winrate < 53) {
    return "A";
  } else if (winrate < 55) {
    return "S";
  } else {
    return "S+";
  }
}

function getTierColor(winrate) {
  if (!winrate || isNaN(winrate)) {
    return "";
  }

  if (winrate < 45) {
    return "shinggo-tier";
  } else if (winrate < 48.5) {
    return "meh-tier";
  } else if (winrate < 51.5) {
    return "okay-tier";
  } else if (winrate < 53) {
    return "good-tier";
  } else if (winrate < 55) {
    return "great-tier";
  } else {
    return "volxd-tier";
  }
}

function getTierColorHex(winrate) {
  if (!winrate || isNaN(winrate)) {
    return "";
  }

  if (winrate < 45) {
    return "#FF4E50";
  } else if (winrate < 48.5) {
    return "#FCB1B2";
  } else if (winrate < 51.5) {
    return "#FFFFFF";
  } else if (winrate < 53) {
    return "#7EA4F4";
  } else if (winrate < 55) {
    return "#3273FA";
  } else {
    return "#FF9B00";
  }
}

function getApiRole(filterType) {
  let apiRole = null;
  switch (filterType) {
    case "top":
      apiRole = "top";
      break;
    case "jungle":
      apiRole = "jungle";
      break;
    case "middle":
      apiRole = "mid";
      break;
    case "adc":
      apiRole = "adc";
      break;
    case "support":
      apiRole = "supp";
      break;
    default:
      apiRole = "all";
  }
  return apiRole;
}

function getLuTier(stdevs) {
  if (stdevs >= 2) {
    return "S+";
  } else if (stdevs >= 0.75) {
    return "S";
  } else if (stdevs >= 0) {
    return "A";
  } else if (stdevs >= -0.5) {
    return "B";
  } else if (stdevs >= -0.75) {
    return "C";
  } else {
    return "D";
  }
}

function getLuTierColor(stdevs) {
  if (stdevs >= 2) {
    return "volxd-tier";
  } else if (stdevs >= 0.75) {
    return "great-tier";
  } else if (stdevs >= 0) {
    return "good-tier";
  } else if (stdevs >= -0.5) {
    return "okay-tier";
  } else if (stdevs >= -0.75) {
    return "meh-tier";
  } else {
    return "shinggo-tier";
  }
}

function getLuTierColorHex(stdevs) {
  if (stdevs >= 2) {
    return "#FF9B00";
  } else if (stdevs >= 0.75) {
    return "#3273FA";
  } else if (stdevs >= 0) {
    return "#7EA4F4";
  } else if (stdevs >= -0.5) {
    return "#FFFFFF";
  } else if (stdevs >= -0.75) {
    return "#FCB1B2";
  } else {
    return "#FF4E50";
  }
}

export {
  numberWithCommas,
  getTierColor,
  getTierColorHex,
  getTier,
  getTierListPickRateCutoff,
  getLuTier,
  getLuTierColor,
  getLuTierColorHex,
  getApiRole,
};
